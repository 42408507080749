<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('huaz.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-form>
        <van-row>
          <van-cell :title="$t('huaz.out')" center>
            <template #icon>
              <span class="dot_green"></span>
            </template>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item
                  v-model="from"
                  :options="fromoptions[langindex]"
                  @change="change"
                />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-cell :title="$t('huaz.in')" center>
            <template #icon>
              <span class="dot_green"></span>
            </template>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item
                  v-model="to"
                  :options="tooptions[langindex]"
                />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 12px 0px;"
            v-for="(item, index) in balance"
            :key="item.id"
          >
            {{ fromoptions[langindex][index].text }}{{ $t('huaz.text')
            }}{{ item.value }}
          </div>

          <van-cell :title="$t('huaz.biz')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="bzvalue" :options="bizhong" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-field
            :label="$t('huaz.number')"
            :placeholder="$t('huaz.enter')"
            v-model="value"
            clearable
            ><template #button>
              <van-button size="small" type="primary" @click.prevent="all">{{
                $t('huaz.allbtn')
              }}</van-button>
            </template>
          </van-field>
        </van-row>
        <van-button class="submitBtns" @click.prevent="submit">{{
          $t('common.submit')
        }}</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      from: 1,
      fromoptions: [
        [
          { text: '币币账户', value: 1 },
          { text: '法币账户', value: 2 },
          { text: '创新', value: 3 },
          { text: 'DeFi', value: 4 }
        ],
        [
          { text: 'Exchange account', value: 1 },
          { text: 'Fiat account', value: 2 },
          { text: 'Innovate', value: 3 },
          { text: 'DeFi', value: 4 }
        ]
      ],
      to: 2,
      tooptions: [
        [
          { text: '法币账户', value: 2 },
          { text: '创新', value: 3 },
          { text: 'DeFi', value: 4 }
        ],
        [
          { text: 'Fiat account', value: 2 },
          { text: 'Innovate', value: 3 },
          { text: 'DeFi', value: 4 }
        ]
      ],
      bzvalue: 0,
      bizhong: [{ text: 'USDT', value: 0 }],
      // 划转数量
      value: '',
      balance: [],
      lang: localStorage.getItem('lang') || 'zh',
      langindex: localStorage.getItem('lang') === 'tw' ? 0 : 1
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/user/transfer')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data
        }
      }
    },
    change() {
      this.value = ''
      this.tooptions[this.langindex] = []
      this.fromoptions[this.langindex].forEach(item => {
        if (item.value !== this.from) {
          this.tooptions[this.langindex].push(item)
        }
      })
      this.to = this.tooptions[this.langindex][0].value
    },
    all() {
      this.value = this.balance[this.from - 1].value
    },
    async submit() {
      const postform = {
        from: this.balance[this.from - 1].name,
        to: this.balance[this.to - 1].name,
        number: this.value
      }
      const { data } = await this.$http.post('/home/user/transfer', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          this.$router.push('/assets')
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.98667rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
    .dot_green {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #004ea3;
      margin-right: 8px;
      display: inline-block;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.98667rem;
    /deep/.van-field__label {
      color: #323232;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
  .submitBtns {
    margin-top: 40%;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
